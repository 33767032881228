<template>
	<div class="bigBox">
		<h2>设备管理</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<el-form ref="form" :model="form" label-width="80px">
					<div class="flex-sb">
						<div class="row-me">
							<el-input v-model="form.keyword" prefix-icon="Search" placeholder="IEME编码/设备编码/设备名称"
								class="accountPut"></el-input>
							<el-select v-model="form.state" placeholder="设备状态" class="electIpt" @focus="stateSelect">
								<el-option v-for="(item,index) in stateList" :key="index" :value="item.id"
									:label="item.title">
								</el-option>
							</el-select>

							<el-select v-model="form.company_id" placeholder="所属公司" class="electIpt" @focus="comSelect">
								<el-option v-for="(item,index) in comList" :key="index" :value="item.id"
									:label="item.name">
								</el-option>
							</el-select>
							<el-button type="primary" icon="Search" class="searchs" @click="deviceSearch">
								搜索</el-button>
							<el-button class="agains" @click="resetBtn">重置
							</el-button>
						</div>
						<div class="row-me row-center addDiv" @click="consumption">
							<img src="../../assets/img/icon_yzxhtj.png" class="iconImg" />&nbsp;
							<span class="youzhi">油脂消耗</span>
						</div>
					</div>
				</el-form>
			</div>
			<div class="tabsBox  margin-top20">
				<el-table ref="singleTable" :data="deviceTabList" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column prop="name" label="设备名称" align="center">
					</el-table-column>
					<el-table-column prop="ieme_encoded" label="IEMI编码" align="center">
					</el-table-column>
					<el-table-column prop="device_encoded" label="设备编码" align="center">
					</el-table-column>
					<el-table-column prop="areainfo" label="所在地区" align="center">
					</el-table-column>
					<el-table-column prop="company_name" label="所属公司" align="center">
					</el-table-column>
					<el-table-column prop="use_time" label="运行时间" align="center">
					</el-table-column>
					<el-table-column prop="state" label="设备状态" align="center">
						<template #default="scope">
							<!-- //设备状态：1：正常，2：异常，3：离线 -->
							<div style="color: #33C77C;" v-if="scope.row.state == 1">正常</div>
							<div style="color: #EB4B4D;" v-if="scope.row.state == 2">异常</div>
							<div style="color: #9D9FA3;" v-if="scope.row.state == 3">离线</div>
						</template>
					</el-table-column>
					<el-table-column prop="signal_state" label="信号状态" align="center">
					</el-table-column>
					<el-table-column prop="use_time" label="始用时间" align="center">
					</el-table-column>
					<el-table-column prop="create_time" label="创建时间" align="center">
					</el-table-column>
					<el-table-column label="操作" align="center" width="150">
						<template #default="scope">
							<el-button type="text" size="small" @click="deviceDetalis(scope.row)">详情
							</el-button>
							<el-button type="text" size="small"
								v-if="scope.row.state == 2 && this.powerListName.includes('复位按钮')"
								@click="resetClick(scope.row)">复位
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="row-me row-center flex-end1 margin-top10" v-if="deviceTabList.length>0">
					<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
						:current-page.sync="pageNo" :total="total">
					</el-pagination>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		deviceListInfo,
		deviceCateOption,
		deviceStateOption,
		sysCompanyOptionList
	} from "@/api/deviceManagement/deviceManagement.js"
	import {
		getRequest,
		postRequest
	} from "@/http/http.js"
	export default {
		data() {
			return {
				form: {
					keyword: "",
					state: "",
					// apply_id: "",
					company_id: ""
				},
				deviceTabList: [],
				page: "",
				stateList: "",
				appleList: '',
				comList: "",
				allListNumber: "",
				pageNo: 1,
				total: 0,
				powerListName: []
			}
		},
		mounted() {
			var list = JSON.parse(sessionStorage.getItem('menuArrayRegulate'));
			console.log(list, 'listlist');
			var nameList = [];

			for (var i = 0; i < list.length; i++) {
				if (list[i].name == "设备管理") {
					for (var j = 0; j < list[i].children.length; j++) {

						if (list[i].children[j].name == "设备管理") {
							nameList = list[i].children[j].children;
						}
					}
				}
			}
			if (nameList != undefined) {
				for (var i = 0; i < nameList.length; i++) {
					this.powerListName.push(nameList[i].name)
				}
			}

			console.log(this.powerListName, '最后按钮')
			this.getDeviceTabList()
		},
		methods: {
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getDeviceTabList()
			},
			// 设备管理表格
			async getDeviceTabList() {
				const res = await deviceListInfo({
					keyword: this.form.keyword,
					state: this.form.state,
					company_id: this.form.company_id,
					page: this.pageNo,
				})
				this.deviceTabList = res.data.listInfo
				this.total = res.data.allListNumber
			},
			// 设备详情
			deviceDetalis(row) {
				this.$router.push(`/goDeviceDetalis/${row.id}`)
			},
			deviceReset(id) {
				this.$post('/admin/deviceReset', {
					id: id
				}).then((res) => {
					if (res) {
						this.$message.success('复位成功')
						this.getDeviceTabList()
					}
				}).catch((error) => {
					console.log(error)
				})
			},
			resetClick(row) {
				var that = this;
				this.$confirm("确定进行复位处理？", "复位", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					that.deviceReset(row.id);
				}).catch(() => {

				});
			},
			// 设备状态下拉框
			async stateSelect() {
				const res = await deviceStateOption()
				this.stateList = res.data
			},
			// 设备应用下拉框
			async appleSelect() {
				const res = await deviceCateOption()
				this.appleList = res.data
			},
			// 所属公司下拉框
			async comSelect() {
				const res = await sysCompanyOptionList()
				this.comList = res.data
			},
			// 搜索
			deviceSearch() {
				this.getDeviceTabList()
			},
			// 重置
			resetBtn() {
				this.form.keyword = ''
				this.form.state = ''
				this.form.company_id = ''
				this.pageNo = 1;
				this.getDeviceTabList()
			},
			// 油脂消耗统计
			consumption() {
				this.$router.push("/consumption")
			}
		},

	}
</script>

<style lang="scss" scoped="scoped">
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.addDiv {
		border: 1px solid #D4D7E0;
		border-radius: 4px;
		padding: 7px 10px;
		cursor: pointer;

		.iconImg {
			width: 26px;
			height: 26px;
		}

		.youzhi {
			font-size: 18px;
		}
	}

	.accountPut {
		margin-right: 0px;

		/deep/.el-input__wrapper {
			width: 360px;
			height: 38px;
		}
	}

	.agains {
		padding: 0 31px !important;
		margin-left: 10px;
		height: 40px;
		font-size: 16px;
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;

		/deep/.el-input {
			width: 200px;
			height: 40px;
		}
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}
</style>